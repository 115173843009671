@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic,800&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Rubik:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Rubik+Mono+One:regular,bold,italic&subset=latin,latin-ext');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Open Sans', sans-serif;
}

#root {
  display: flex;
}
