@import url(https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic,800&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rubik:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Rubik+Mono+One:regular,bold,italic&subset=latin,latin-ext);
body {
  background-color: white
}


.box {
  color: white;
  border-radius: 2px;
  width: 220px;
  height: 180px;
  margin-right: 5px;
}

.box > h3 {
  font-size: 19px;
}
.box > p {
  margin: 0px;
  font-size: 16px;
}

.box:hover {
  border-style: solid;
  border-width: 2px;
  border-color: black;
  border-radius: 2px;
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
}
.box-row-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  height: 180px;
}

@media (max-width: 1220px) {
  .box-row-container {
    margin-left: 10px;
  }
}

@media (max-width: 700px) {
  .box-row-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
    height: auto;
  }
  .box {
    margin-right: 0px;
    margin-left: 0px;
    width: 260px;
    height: 220px;
    margin-bottom: 5px;
  }
  .box > h3 {
    font-size: 21px;
  }
  .box > p {
    margin: 0px;
    font-size: 18px;
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Open Sans', sans-serif;
}

#root {
  display: flex;
}

