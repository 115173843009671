
.box {
  color: white;
  border-radius: 2px;
  width: 220px;
  height: 180px;
  margin-right: 5px;
}

.box > h3 {
  font-size: 19px;
}
.box > p {
  margin: 0px;
  font-size: 16px;
}

.box:hover {
  border-style: solid;
  border-width: 2px;
  border-color: black;
  border-radius: 2px;
  transform: rotate(2deg);
}
.box-row-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  height: 180px;
}

@media (max-width: 1220px) {
  .box-row-container {
    margin-left: 10px;
  }
}

@media (max-width: 700px) {
  .box-row-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-left: 0px;
    height: auto;
  }
  .box {
    margin-right: 0px;
    margin-left: 0px;
    width: 260px;
    height: 220px;
    margin-bottom: 5px;
  }
  .box > h3 {
    font-size: 21px;
  }
  .box > p {
    margin: 0px;
    font-size: 18px;
  }
}